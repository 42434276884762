

$('.answer_button').on("click", function(e) {
    e.preventDefault();
    var question_id = $(this).attr('data_id');
    var answer_id = $('input[name=question_' + question_id + ']:checked').val();
    if (answer_id == undefined) {
        show_error(lang.noChoice, '<span class="fa fa-frown-o" aria-hidden="true"></span>', lang.incorrect);
    } else {
        // console.log('Klausima ID: ' + question_id + ' atsake atsakymu ID: ' + answer_id);
        $.post( '/questions/check_answer/', { answer_id: answer_id, question_id: question_id }, function (response) {
            response = JSON.parse(response);
            // console.log(response.success);
            if(response.video_url != '' && response.video_url != null ){

                var windowWidth = $(window).width();
                var height;
                if(windowWidth > 768) {
                    height = 297;
                }else{
                    height = 200;
                }

                var $html = '<iframe id="player" width="100%" height="' + height + '" src="' + response.video_url + '?rel=0&amp;controls=0&amp;showinfo=0&autoplay=1&enablejsapi=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>';
                $('.error_video').html($html);
                onYouTubeIframeAPIReady();
                $('.error_button_holder').fadeToggle('fast');
            }

            if (response.success == 1) {
                show_error(response.comment, '<span class="fa fa-smile-o" aria-hidden="true"></span>', lang.correct);
                //
                // var owl = $('.question-carousel');
                // owl.trigger('owl.next');
            } else {
                show_error(response.comment, '<span class="fa fa-frown-o" aria-hidden="true"></span>', lang.incorrect);
            }
        } );
    }
});

$('.error_button').on("click", function(e) {
      $( ".error_layer" ).fadeOut( "fast", function() {
        if($( ".error_logo").html().indexOf('smile') >= 0) {
            var owl = $('.question-carousel');
            owl.trigger('owl.next');
        }

        $( ".error_video" ).html('');
      });
});

function show_error(text, logo, button_text) {
      $( ".error_text").text(text);
      $( ".error_logo").html(logo);
      $( ".error_button").text(button_text);
      $( ".error_layer" ).fadeIn( "fast", function() {
      });
}

$(document).ready(function(){
    var whichSlide = 0;
  $('.question-carousel').owlCarousel({
      items: 1,
      navigation: false,
      mouseDrag: false,
      touchDrag: false,
      pagination: false,
      responsive: true,
      singleItem: true,
      responsiveRefreshRate: 1,
      beforeMove: function() {
          whichSlide++;
          $('.current-question').text(whichSlide+1);
          if (whichSlide == questionsCount) {
              window.location.replace('/authorize/showSignUp/' + sessionID + '/' + sessionIP);
          }
//          console.log(whichSlide);
      }
  });
});